<template>
    <div class="relative">
        <div class="w-full absolute block z-20">
            <Header/>
        </div>
        <div class="space-top pn-plc-banner-container w-full">
            <div class="relative h-full w-full flex justify-center items-start flex-col">
                <img class="absolute top-0 right-0 h-full object-cover z-5" src="../../../assets/images/home-page/pn-plc/pn-plc-banner.png" alt="PLC Perks Banner">
                <div class="flex flex-col mx-44 mobileL:mx-0 z-10 p-0 mobileL:p-4 text-left mobileL:text-center ml-6 mobileL:ml-20 lg:ml-32 2xl:ml-44 
                relative mt-8 mobileL:mt-0 max-w-xs mobileL:max-w-xs lg:max-w-md 2xl:max-w-xl">                  
                    <span class="pn-plc-bg-highlight absolute w-4/5 mobileL:w-full h-4 mobileS:h-7 mobileL:h-1/6 2xl:h-16 top-3 mobileL:top-8 lg:top-10 2xl:top-12 z-0"></span>
                    <h1 class="pn-plc-banner-title font-kannada font-bold mobileS:text-2xl xs:text-3xl md:text-5xl max-w-sm 2xl:max-w-lg lg:text-6xl 2xl:text-8xl z-10">
                        PLC Perks
                    </h1>
                    <img class="absolute mobileL:hidden -top-2 shine-position w-3.5 z-0" src="../../../assets/images/home-page/shine-sm.png" alt="">
                    <p class="mobileL:font-kannada mobileL:mt-4 2xl:mt-6 text-sm mobileL:text-lg lg:text-2xl 2xl:text-4xl -mx-4 2xl:-mx-8 text-white-1
                        leading-tight mobileL:leading-normal pl-4 -mr-20 mobileL:mr-0 ">
                        We want to make you feel extra special with the VIP treatment you deserve. 
                        (It's really our way of thanking you for your trust and having confidence 
                        in us through and through.)
                    </p>
                </div>
            </div>
        </div>
        <div class="relative">
            <img class="w-full h-full absolute object-cover" src="../../../assets/images/home-page/pn-plc/pn-update-bg.png" alt="">
            <div class="relative top-0 left-0 w-full h-full flex items-center justify-center flex-col py-4 mobileL:py-12">
                <h2 class="pn-update-title flex text-4xl mobileL:text-6xl lg:text-10xl 2xl:text-10xl">
                    Update
                </h2>
                <p class="text-white-1 text-sm font-montserrat -mt-2 mobileL:mt-0 md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl w-4/5 mobileL:w-3/5 text-center mobileL:pb-4">
                    PLC application is currently on hold. Stay tuned for 
                    further announcements on our social media channels. 
                    For other PLC concerns, please visit the Contact Us 
                    tab or send an email to
                    <span><a class="underline" href="mailto:customercare@lay-bare.com">
                    customercare@lay-bare.com
                    </a></span>
                </p>
            </div>
        </div>
        <div class="relative pn-bg-partners py-6 mobileL:py-10">
            <div class="relative font-kannada top-0 left-0 w-full h-full flex items-center justify-center flex-col text-white-1 mobileL:pt-8">
                <p class="text-center mobileL:font-semibold mobileL:text-lg lg:text-xl xl:text-2xl 2xl:text-4xl w-5/6 leading-tight mobileL:leading-normal"> 
                    The Premier Loyalty Card (PLC) is our rewards program that lets our
                    most loyal clients enjoy amazing perks from our merchants partners
                    and a 10% discount on all our services for life. (And yes, we mean
                    it, FOR THE REST OF YOUR LIFE.)
                </p>
                <p class="text-center mx-8 mobileL:mx-0 text-xs mobileL:text-base lg:text-lg xl:text-xl 2xl:text-3xl mt-2 lg:mt-12">
                    Also in partnership with for exclusive freebies, discounts and more!
                </p>
                <div class="mx-auto px-5 mobileL:pb-8">
                    <div class="mt-2 lg:mt-12 flex justify-between flex-wrap gap-3 md:gap-8 lg:gap-20 2xl:gap-24">
                        <img class="w-24 lg:w-32 xl:w-40 md:w-28 2xl:w-48 mx-auto" src="../../../assets/images/home-page/plc-perks/azumi.png" alt="Azumi"/>
                        <img class="w-24 lg:w-32 xl:w-40 md:w-28 2xl:w-48 mx-auto" src="../../../assets/images/home-page/plc-perks/azta.png" alt="Azta"/>
                        <img class="w-24 lg:w-32 xl:w-40 md:w-28 2xl:w-48 mx-auto" src="../../../assets/images/home-page/plc-perks/dessert-museum.png" alt="Dessert Museum"/>
                        <img class="w-24 lg:w-32 xl:w-40 md:w-28 2xl:w-48 mx-auto" src="../../../assets/images/home-page/plc-perks/enchanted-kingdom.png" alt="Enchanted Kingdom"/>
                        <img class="w-24 lg:w-32 xl:w-40 md:w-28 2xl:w-48 mx-auto"  src="../../../assets/images/home-page/plc-perks/inflatable-island.png" alt="Inflatable Island"/>
                    </div>
                </div>
            </div>
        </div>
        <div class="bg-green-15 py-8 lg:py-8 text-white-1 relative">
            <div class="text-center mobileL:mb-4 relative">
                <div class="relative inline-block">
                    <span class="pn-how-to-get-highlight absolute top-4 mobileL:top-8 lg:top-10 2xl:top-12 left-0 w-full h-1/3 2xl:h-1/2 z-10"></span>
                    <img class="absolute -top-2 mobileL:top-0 -left-3 mobileL:-left-6 w-3.5 mobileL:w-4 lg:w-5 z-0" src="../../../assets/images/home-page/shine-sm.png" alt="">
                    <div class="text-3xl mobileL:text-6xl lg:text-7xl 2xl:text-9xl font-semibold pb-6 relative z-20">
                        <h2 class="font-kannada font-semibold">How to get one?</h2>
                    </div>
                    <img class="absolute -top-2 mobileL:top-0 -right-8 mobileL:-right-16 lg:-right-20 z-0 w-12 lg:w-32 2xl:w-auto mobileL:w-24" src="../../../assets/images/home-page/sketch-line.png" alt="">
                </div>
            </div>
            <div class="flex-row">
                <div class="mx-auto w-5/6 2xl:gap-20 font-montserrat md:gap-12 flex flex-col mobileL:flex-row md:text-lg lg:text-xl xl:text-2xl 2xl:text-4xl">
                    <div class="flex-1 text-center flex flex-col items-center mb-6">
                        <img class="w-8 mobileL:w-14 mb-4" src="../../../assets/images/home-page/lbplus-plc/1.png" alt="1">
                        <h4 class="">Download and Register in our app!</h4>
                    </div>
                    <div class="flex-1 text-center flex flex-col items-center mb-6">
                        <img class="w-8 mobileL:w-14 mb-4" src="../../../assets/images/home-page/lbplus-plc/2.png" alt="2">
                        <h4 class="">You must spend an accumulated amount worth Php 5,000.00 on any of our services through booking made in the app</h4>
                    </div>
                    <div class="flex-1 text-center flex flex-col items-center mb-6">
                        <img class="w-8 mobileL:w-14 mb-4" src="../../../assets/images/home-page/lbplus-plc/3.png" alt="3">
                        <h4 class="">Your profile will automatically tagged as a PLC holder. No need to apply!</h4>
                    </div>
                </div>
                <div class="mobileL:mt-12 2xl:mt-16">
                    <div class="flex justify-center items-center gap-4 flex-col mobileL:flex-row">
                    <a href="https://apps.apple.com/ph/app/lay-bare/id1492384941" target="_blank">
                        <img class="w-36 2xl:w-44" src="../../../assets/images/home-page/lbplus-plc/apple.png" alt="App store"/>
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.lbo.mobile.laybare&hl=en&gl=US" target="_blank">
                        <img class="w-36 2xl:w-44" src="../../../assets/images/home-page/lbplus-plc/google-play.png" alt="Google playstore"/>
                    </a>
                    <a href="https://appgallery.huawei.com/#/app/C103688251" target="_blank">
                        <img class="w-36 2xl:w-44" src="../../../assets/images/home-page/lbplus-plc/huawei.png" alt="Huawei"/>
                    </a>
                    </div>
                </div>
            </div>

        </div>
        <div class="w-full absolute block z-20">
            <Footer/>
        </div>
    </div>
</template>

<script>
import Header from '../../../components/navigation/LaybareHeader.vue'
import Footer from '../../../components/footer/PassionailsFooter.vue'

export default {
    components: {
        Header,
        Footer,
    },
    mounted() {
        this.$gtag.pageview('/passionails/plc-perks')
    },
}
</script>

<style>
.pn-plc-banner-container {
    height: 700px;
    background-size: cover;
    background: linear-gradient(180deg, #dd9d9e 0%, #dd9d9e 100%);
}

@media screen and (min-width: 640px) and (max-width: 1023px) {
    .pn-plc-banner-container {
        height: 45dvh;
    }
}

.space-top {
  margin-top: 90px;
}

@media (min-width: 1280px) {
  .space-top {
    margin-top: 100px;
    }
}

@media (min-width: 1536px) {
    .pn-plc-banner-container {
      height: 110vh;
    }
    .space-top {
      margin-top: 128px;
    }
}

@media (max-width:480px) {
    .pn-plc-banner-container{
        height:255px;
        background: linear-gradient(180deg, #C87F93 0%, #D8ACBB 100%);
    }
    .space-top {
    margin-top: 60px;
    }
}

.pn-plc-banner-title {
    color: #FFF;
    font-family: Kannada;
    font-style: normal;
    font-weight: 600;
}

.pn-update-title {
    color: #FFF;
    text-align: center;
    font-family: Kannada;
    text-shadow: 0px 3px 0px #80808080;
    font-style: normal;
    font-weight: 600;
}

@media only screen and (min-width: 768px) {
    .pn-update-title {
        text-shadow: 0px 4px 0px #80808080;
    }
}

@media only screen and (min-width: 992px) {
    .pn-update-title {
        text-shadow: 0px 6px 0px #80808080;
    }
}

@media only screen and (min-width: 1200px) {
    .pn-update-title {
        text-shadow: 0px 8px 0px #80808080;
    }
}

.pn-bg-partners {
    background: linear-gradient(180deg, #7C4979 -43.8%, #E1B6EB 151.66%);
}

.pn-plc-bg-highlight {
    position: absolute;
    background: #7D4979;
    left: 0px;
}

.pn-how-to-get-highlight {
    position: absolute;
    background: #E1B6EB;
    left: 0px;
}

.shine-position {
    left:-8px
}

</style>